import React from "react";
import Grid from "@material-ui/core/Grid";
import httpStatus from "http-status";

import { ErrorTextTitle, ErrorTextDescription, Wrapper } from "./styled";

const ErrorPage = props => {
  const { errorCode } = props;
  let errorImage = "";
  let errorTitle = "";
  let errorDescription = "";
  const urlWebview = "https://storage.googleapis.com/leanbiz-core/static/webview/";

  switch (errorCode) {
    case httpStatus.NOT_FOUND:
      errorImage = urlWebview + "404.svg";
      errorTitle = "Page not found";
      errorDescription = "We can't seem to find the page you're looking for.";
      break;
    case httpStatus.INTERNAL_SERVER_ERROR:
      errorImage = urlWebview + "500.svg";
      errorTitle = "Internal server error";
      errorDescription = "Things are little unstable here. Please be patient or try again later.";
      break;
    case httpStatus.SERVICE_UNAVAILABLE:
      errorImage = urlWebview + "503.svg";
      errorTitle = "Service unavailable";
      errorDescription =
        "This site is getting a tune-up. Why don’t you come back in a little while, we’ll up and running soon! If you need help for urgent issues please email us at contact@deeple.ai";
      break;
    case httpStatus.REQUEST_TIMEOUT:
      errorImage = urlWebview + "408.svg";
      errorTitle = "Request timeout";
      errorDescription = "This request takes too long to process it is timed out by the server";
      break;
    case httpStatus.BAD_GATEWAY:
      errorImage = urlWebview + "502.svg";
      errorTitle = "Bad gateway";
      errorDescription =
        "Service temporarily overloaded. We are apologize for the inconvenience. Please be patient or try again later.";
      break;
    case "DEVICE_NOT_SUPPORT":
      errorImage = urlWebview + "page-not-available.svg";
      errorDescription = "You can open this page via the smartphone and tablet versions of LINE.";
      break;
    default:
      break;
  }

  return (
    <Wrapper container direction="column" justify="center" alignItems="center">
      <Grid item className="pb-4">
        <img alt="error_image" src={errorImage} width="100%" />
      </Grid>
      <Grid item className="px-4 pb-4 text-center">
        <ErrorTextTitle>{errorTitle}</ErrorTextTitle>
      </Grid>
      <Grid item className="px-4 text-center">
        <ErrorTextDescription>{errorDescription}</ErrorTextDescription>
      </Grid>
    </Wrapper>
  );
};

export default ErrorPage;
