import { ShoppingCartCalculator, getSerializeCartProducts } from "@leanbizai/shopping-cart-calculator";
import React from "react";
import { withTranslation } from "react-i18next";
import get from "lodash/get";

import EmptyCartIcon from "./empty-cart.png";
import HttpClientService from "../../../services/http-client";
import ProductListItem from "./ProductListItem";
import { CART, SHIPPING } from "../../../constant";
import { Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { TitleContainer } from "../../../utils/common";
import { withQueryProjectId } from "../../../hoc";
import { withRouter } from "react-router-dom";

import {
  getBackwardCompatibleVariableTypes,
  getRelatedPromotionByProductSKUIds,
  sortPromotionByPriority,
} from "../../../utils/product";

import { BottomUpdateCartContainer } from "./MyCart.styled";

const httpClientService = new HttpClientService();

const MY_CART_LABEL = {
  TITLE: "CART_SUMMARY",
  INNER_TITLE: "PRODUCT_LISTS",
  BUTTON: {
    CONTINUE_SHOPPING: "CONTINUE_SHOPPING",
  },
  OUT_OF_CART: "OUT_OF_CART",
};

class MyCart extends React.Component {
  state = {
    productSKUs: [],
    promotions: [],
    loading: false,
    saving: false,
  };

  componentDidMount() {
    this.fetchCart();
  }

  fetchCart = async () => {
    this.setState({ loading: true });
    try {
      const { projectId } = this.props;
      const {
        data: { productSKUs, promotions },
      } = await httpClientService.getCart(projectId);

      this.setState({
        productSKUs: productSKUs || [],
        promotions: promotions || [],
      });
    } catch (e) {
      await httpClientService.closeWindow();
    } finally {
      this.setState({ loading: false });
    }
  };

  saveCart = async () => {
    this.setState({ saving: true });
    try {
      const { projectId } = this.props;
      const { productSKUs } = this.state;
      const cartProducts = getSerializeCartProducts(productSKUs);
      await httpClientService.updateCart(projectId, cartProducts, CART.ACTION.SAVE);

      await httpClientService.closeWindow();
    } catch (e) {
      await httpClientService.closeWindow();
    } finally {
      this.setState({ saving: false });
    }
  };

  handleChangeAmount = (addOrReduce, index) => {
    const { productSKUs } = this.state;
    const newAmount = addOrReduce === "add" ? productSKUs[index].amount + 1 : productSKUs[index].amount - 1;
    if (newAmount > 99 || newAmount < 1 || newAmount > productSKUs[index].stock) {
      return;
    }
    const newProductSKUs = [
      ...productSKUs.slice(0, index),
      { ...productSKUs[index], amount: newAmount },
      ...productSKUs.slice(index + 1),
    ];
    this.setState({ productSKUs: newProductSKUs });
  };

  handleDeleteItem = index => {
    const { productSKUs } = this.state;
    const newProductSKUs = [...productSKUs.slice(0, index), ...productSKUs.slice(index + 1)];
    this.setState({ productSKUs: newProductSKUs });
  };

  calculateProductSKUs = () => {
    const { productSKUs } = this.state;
    return productSKUs.reduce((prev, cur) => prev + cur.amount * cur.price, 0);
  };

  calculateAllItemsInProductSKUs = productSKUs => {
    return productSKUs.reduce((prev, cur) => prev + cur.amount, 0);
  };

  render() {
    const { productSKUs, promotions, loading, saving } = this.state;
    const { t } = this.props;
    const isCartEmpty = productSKUs.length === 0;
    let amountOfAllItemsInCart = productSKUs.length;
    let shoppingCartCalculator, cartSummary;
    if (!isCartEmpty) {
      shoppingCartCalculator = new ShoppingCartCalculator(productSKUs);

      cartSummary = shoppingCartCalculator
        .setShipping({ method: SHIPPING.METHODS.ALL, rate: 0 })
        .setBulkPromotionWithPromotionProperties(promotions)
        .calculate();

      amountOfAllItemsInCart = this.calculateAllItemsInProductSKUs(cartSummary.productSKUs);
    }

    return (
      <>
        <Helmet>
          <title>{t(MY_CART_LABEL.TITLE)}</title>
        </Helmet>

        <div style={{ paddingBottom: "100px" }}>
          <TitleContainer>
            <Grid container justify="space-between">
              <Grid item>{t(MY_CART_LABEL.INNER_TITLE)}</Grid>
              <Grid item style={{ paddingRight: "15px" }}>
                {`${amountOfAllItemsInCart} ${t("ITEMS")}`}
              </Grid>
            </Grid>
          </TitleContainer>

          <Grid container>
            {loading ? (
              <Grid container item>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    marginTop: "100px",
                  }}
                >
                  <CircularProgress size={150} />
                </div>
              </Grid>
            ) : isCartEmpty ? (
              <Grid container alignItems="center" justify="center" style={{ position: "absolute", top: "20%" }}>
                <Grid container item spacing={2} alignItems="center" justify="center">
                  <Grid item>
                    <img
                      src={EmptyCartIcon}
                      alt="Empty Cart"
                      style={{
                        height: "150px",
                        width: "150px",
                        border: "none",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="subheading">{t(MY_CART_LABEL.OUT_OF_CART)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              cartSummary &&
              cartSummary.productSKUs.map((productSKU, index) => {
                const productImage = productSKU.image || get(productSKU, "product.images[0].src");
                const allRelatedPromotions = getRelatedPromotionByProductSKUIds([productSKU.id], promotions);
                const sortedPromotions = allRelatedPromotions.sort(sortPromotionByPriority);

                return (
                  <ProductListItem
                    key={index}
                    img={productImage}
                    name={get(productSKU, "product.name")}
                    variableTypes={getBackwardCompatibleVariableTypes(productSKU)}
                    price={productSKU.price}
                    amount={productSKU.amount}
                    isFree={productSKU.isFree}
                    max={productSKU.stock > 99 ? 99 : productSKU.stock}
                    promotions={sortedPromotions}
                    type={get(productSKU, "product.type")}
                    options={productSKU.options}
                    note={productSKU.note}
                    digitalContentOptions={get(productSKU, "digitalContentOptions")}
                    onAmountChange={addOrReduce => this.handleChangeAmount(addOrReduce, index)}
                    onDelete={() => this.handleDeleteItem(index)}
                  />
                );
              })
            )}
          </Grid>
        </div>

        <BottomUpdateCartContainer>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography style={{ color: "#737d94" }}>{t("TOTAL_PRICE")}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="primary" variant="h6" style={{ fontWeight: "bold", marginLeft: "6px" }}>
                    ฿
                    {cartSummary
                      ? cartSummary.grandTotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption" style={{ color: "#a1a6b9" }}>
                      {Boolean(promotions.length) && `*${t("TOTAL_PRICE_INCLUDE_PROMOTIONS")}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                disabled={saving}
                style={{ borderRadius: "16px", padding: "10px 20px" }}
                variant="contained"
                color="primary"
                onClick={this.saveCart}
              >
                {t("CONTINUE_SHOPPING")}
              </Button>
            </Grid>
          </Grid>
        </BottomUpdateCartContainer>
      </>
    );
  }
}

export default withRouter(withQueryProjectId(withTranslation(["MY_CART"])(MyCart)));
