import { ShoppingCartCalculator, getUniqueShippingRules, getUniqueShippingRulesByShippingId } from "@leanbizai/shopping-cart-calculator";
import get from "lodash/get";

import config from "../../config"
import { SHIPPING_CALCULATE_TYPE } from "../../constants/shipping";

const { IS_USING_NEW_SHIPPING_SERVICE } = config

const calculateLegacyCart = (cartsInput,
  activePromotions,
  vatRateInput,
  vatOptions,
  shipping,
  isShippingIncluded = false,
  hasSKUProductSKUs = true
) => {
  const formattedCart = cartsInput.map(item => ({
    ...item,
    product: item.product,
    id: item.id,
    stock: item.stock,
    price: Number(item.price),
    productCode: item.productCode,
    weight: 0, // weight is not affect to calculator but ProductSKU type required
  }));
  const cartsWithoutFreeProduct = formattedCart.filter(cart => !(cart.isFree && !cart.isAddedByAdmin));

  try {
    const shoppingCartCalculator = new ShoppingCartCalculator(cartsWithoutFreeProduct).setVat({
      options: vatOptions,
      rate: vatRateInput,
      isShippingIncluded,
    });

    if (hasSKUProductSKUs) {
      if (shipping && shipping.calculateType !== SHIPPING_CALCULATE_TYPE.DISTANCE_BASE_PRICE) {
        const shippingRules = getUniqueShippingRules(cartsWithoutFreeProduct, shipping.method);
        shoppingCartCalculator.setShipping(shipping).setBulkShippingRuleWithShippingRuleProperties(shippingRules);
      } else if (shipping) {
        shoppingCartCalculator.setShipping(shipping);
      }

      if (shipping && shipping.isCODPayment) {
        const managementFee = get(shipping, "CODPayment.managementFee");
        shoppingCartCalculator.setManagementFee(managementFee);
      }
    }

    const shoppingCartCalculatorData = shoppingCartCalculator
      .setBulkPromotionWithPromotionProperties(activePromotions)
      .calculate();

    const {
      grandTotal,
      productSKUs,
      promotions,
      totalPrice,
      totalProductDiscount,
      totalShippingDiscount,
      vatPrice,
      shipping: shippingMethod,
      totalShippingPrice: shippingPrice,
    } = shoppingCartCalculatorData;

    return {
      grandTotal,
      productSKUs,
      promotions,
      shippingMethod,
      shippingPrice,
      totalPrice,
      totalProductDiscount,
      totalShippingDiscount,
      vatPrice,
    };
  } catch (error) {
    throw new Error(error);
  }
}

const calculateCart = (
  cartsInput,
  activePromotions,
  vatRateInput,
  vatOptions,
  shipping,
  isShippingIncluded = false,
  hasSKUProductSKUs = true,
) => {
  if (!IS_USING_NEW_SHIPPING_SERVICE) {
    return calculateLegacyCart(cartsInput, activePromotions, vatRateInput, vatOptions, shipping, isShippingIncluded, hasSKUProductSKUs)
  }

  const formattedCart = cartsInput.map(item => ({
    ...item,
    product: item.product,
    id: item.id,
    stock: item.stock,
    price: Number(item.price),
    productCode: item.productCode,
    weight: 0, // weight is not affect to calculator but ProductSKU type required
  }));
  const cartsWithoutFreeProduct = formattedCart.filter(cart => !(cart.isFree && !cart.isAddedByAdmin));

  try {
    const shoppingCartCalculator = new ShoppingCartCalculator(cartsWithoutFreeProduct).setVat({
      options: vatOptions,
      rate: vatRateInput,
      isShippingIncluded,
    });

    if (hasSKUProductSKUs) {
      shoppingCartCalculator
        .setSelectedShippingInformation(shipping)
        .setBulkShippingRuleWithShippingRuleProperties(getUniqueShippingRulesByShippingId(cartsWithoutFreeProduct, shipping.shippingId));
    }

    const shoppingCartCalculatorData = shoppingCartCalculator
      .setBulkPromotionWithPromotionProperties(activePromotions)
      .calculate();

    const {
      grandTotal,
      productSKUs,
      promotions,
      totalPrice,
      totalProductDiscount,
      totalShippingDiscount,
      vatPrice,
      selectedShipping,
      totalShippingPrice: shippingPrice,
    } = shoppingCartCalculatorData;

    return {
      grandTotal,
      productSKUs,
      promotions,
      shippingPrice,
      totalPrice,
      totalProductDiscount,
      totalShippingDiscount,
      vatPrice,
      shippingMethod: selectedShipping,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export default calculateCart;
